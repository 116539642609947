.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.Body {
  background-image: url('../images/background.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 70%;
  padding: 40px;
  overflow: scroll;
  min-height: 81vh;
}

.Heading {
  margin-bottom: 20px;
}

html .ListItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}

html .page-title {
  font-size: 40px;
}

.App-link {
  color: #61dafb;
}

@media screen and (max-width: 768px) {
  html .page-title {
    font-size: 24px;
  }
}
