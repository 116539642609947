.home__img {
  width: 200px;
  height: 300px;
  margin-right: 20px;
}

.home__info {
  display: flex;
  margin-bottom: 20px;
}

.home__text {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .home__info {
    flex-direction: column;
  }

  .home__text {
    margin: 20px 0;
  }
}