.meet__img {
  width: 325px;
  height: 325px;
  margin-right: 20px;
}

.meet__info {
  display: flex;
}

.meet__text {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .meet__img {
    width: 225px;
    height: 225px;
  }

  .meet__info {
    flex-direction: column;
  }

  .meet__text {
    margin: 20px 0;
  }
}